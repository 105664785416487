<template>
  <div>
    <label for="phone_number" class="custom-label" v-if="showLabel">{{
      label
    }}</label>
    <WePhoneMaskInput
      v-model="computedData"
      autoDetectCountry
      v-bind:data="computedData"
      v-bind:showFlag="showFlag"
      v-bind:inputClass="getInputClass"
      v-bind:flagClass="getFlagClass"
      v-bind:wrapperClass="getWrapperClass"
    />
  </div>
</template>
<script>
export default {
  name: "WePhone",
  props: {
    data: {
      default: null,
    },
    label: {
      default: "",
    },
    wrapperClass: {
      default: "phone-mask-wrapper-lib",
    },
    showLabel: {
      default: true,
    },
    showFlag: {
      default: true,
    },
    placeholder: {
      default: null,
    },
    size: {
      default: "lg",
    },
  },
  model: {
    prop: "data",
  },
  computed: {
    computedData: {
      get() {
        return this.data;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    getInputClass() {
      let result = "form-control";
      if (this.size == "sm") {
        result += " form-control-sm";
      }
      return result;
    },
    getWrapperClass() {
      return this.wrapperClass;
    },
    getFlagClass() {
      let result = "phone-flag ";
      if (this.size == "sm") {
        result += " phone-flag-sm";
      }
      return result;
    },
  },
};
</script>
<style lang="scss">
.phone-flag {
  position: absolute;
  right: 25px;
}
.phone-flag-sm {
  right: 10px !important;
}
</style>
